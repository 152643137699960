<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    状态评估详情
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="deviceAssessInfo" ref="deviceAssessInfo">
                    <el-form-item label="仪器编号" :label-width="formLabelWidth" prop="did" class="input-lenght">
                        <el-input v-model="deviceAssessInfo.deviceNum" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="仪器名称" :label-width="formLabelWidth" class="input-lenght">
                        <el-input v-model="deviceAssessInfo.deviceName" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="实际名称" :label-width="formLabelWidth" class="input-lenght">
                        <el-input v-model="deviceAssessInfo.deviceRealName" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="规格型号" :label-width="formLabelWidth" class="input-lenght">
                        <el-input v-model="deviceAssessInfo.deviceType" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="使用年限" :label-width="formLabelWidth" prop="useYear">
                        <el-select v-model="deviceAssessInfo.useYear" readonly>
                            <el-option v-for="(item, index) in useYearList" :key="index" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="本月使用频次" :label-width="formLabelWidth" prop="monthUseNum" class="input-lenght">
                        <el-input type="number" v-model="deviceAssessInfo.monthUseNum" autocomplete="off"
                            readonly></el-input>
                    </el-form-item>
                    <el-form-item label="上次维修日期" :label-width="formLabelWidth" prop="lastWxDate" class="input-lenght">
                        <el-date-picker v-model="deviceAssessInfo.lastWxDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期" readonly>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上次校验日期" :label-width="formLabelWidth" prop="lastCheckDate" class="input-lenght">
                        <el-date-picker v-model="deviceAssessInfo.lastCheckDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期" readonly>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上次保养日期" :label-width="formLabelWidth" prop="lastMaintainDate" class="input-lenght">
                        <el-date-picker v-model="deviceAssessInfo.lastMaintainDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期" readonly>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="仪器状态" :label-width="formLabelWidth" prop="deviceStatus">
                        <el-select placeholder="请选择仪器状态" v-model="deviceAssessInfo.deviceStatus" readonly>
                            <el-option v-for="(item, index) in deviceStatusList" :key="index" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="仪器评级" :label-width="formLabelWidth" prop="deviceGrade">
                        <el-select placeholder="请选择仪器评估级别" v-model="deviceAssessInfo.deviceGrade" readonly>
                            <el-option v-for="(item, index) in assessList" :key="index" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评估人" :label-width="formLabelWidth" prop="evaluator" class="input-lenght">
                        <el-input v-model="deviceAssessInfo.evaluator" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="评估日期" :label-width="formLabelWidth" prop="evaluationDate" class="input-lenght">
                        <el-date-picker v-model="deviceAssessInfo.evaluationDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期" readonly>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="评估时间" :label-width="formLabelWidth" prop="evaluationTime"
                        class="input-time-lenght">
                        <el-input prefix-icon="el-icon-time" v-model="deviceAssessInfo.evaluationTime" autocomplete="off"
                            readonly></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" prop="remark" class="input-lenght">
                        <el-input type="textarea" placeholder="请输入内容" v-model="deviceAssessInfo.remark" maxlength="100"
                            show-word-limit readonly></el-input>
                    </el-form-item>

                </el-form>

            </div>

        </el-card>
    </div>
</template>

<script>
import { deviceStatusList } from '@/api/deviceKeepLog.js'
import { assessList, useYearList, getDeviceAssess } from '@/api/deviceAssess.js'

export default {

    props: {
        id: {
            type: [String, Number]
        },
        did: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceStatusList: deviceStatusList,
            assessList: assessList,
            useYearList: useYearList,
            deviceAssessInfo: {},
            formLabelWidth: "120px",
        }
    },
    mounted() {
        this.id && this.loadDeviceAssessInfo()
    },
    methods: {

        async loadDeviceAssessInfo() {
            await getDeviceAssess(this.id).then(res => {
                if (res.code === '000000') {
                    this.deviceAssessInfo = res.t
                }
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        width: 80%;
        text-align: right;
    }

    .input-lenght {
        width: 60%;

    }

    .input-time-lenght {
        width: 340px;
    }




}
</style>